/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  HTTPValidationError,
  LoginApiAdminLoginPutParams,
  ItemTemplate,
  FormData,
  SaveTemplateApiAdminSavePatchParams,
  DeleteTemplateApiAdminIdDeleteParams,
  LoginApiUserLoginPutParams,
  SaveApiUserSavePutParams,
  UserData,
  DataApiUserDataGetParams
} from '.././Models'


/**
 * @summary Login
 */
export const loginApiAdminLoginPut = (
    params: LoginApiAdminLoginPutParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.put(
      `/api/admin/login`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getLoginApiAdminLoginPutMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loginApiAdminLoginPut>>, TError,{params: LoginApiAdminLoginPutParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof loginApiAdminLoginPut>>, TError,{params: LoginApiAdminLoginPutParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof loginApiAdminLoginPut>>, {params: LoginApiAdminLoginPutParams}> = (props) => {
          const {params} = props ?? {};

          return  loginApiAdminLoginPut(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type LoginApiAdminLoginPutMutationResult = NonNullable<Awaited<ReturnType<typeof loginApiAdminLoginPut>>>
    
    export type LoginApiAdminLoginPutMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Login
 */
export const useLoginApiAdminLoginPut = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loginApiAdminLoginPut>>, TError,{params: LoginApiAdminLoginPutParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getLoginApiAdminLoginPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Get
 */
export const getApiAdminGet = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ItemTemplate[]>> => {
    return axios.get(
      `/api/admin/`,options
    );
  }


export const getGetApiAdminGetQueryKey = () => [`/api/admin/`] as const;
  

    
export const getGetApiAdminGetQueryOptions = <TData = Awaited<ReturnType<typeof getApiAdminGet>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getApiAdminGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiAdminGetQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiAdminGet>>> = ({ signal }) => getApiAdminGet({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetApiAdminGetQueryResult = NonNullable<Awaited<ReturnType<typeof getApiAdminGet>>>
export type GetApiAdminGetQueryError = AxiosError<unknown>

/**
 * @summary Get
 */
export const useGetApiAdminGet = <TData = Awaited<ReturnType<typeof getApiAdminGet>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiAdminGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiAdminGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Get Data
 */
export const getDataApiAdminDataGet = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<FormData[]>> => {
    return axios.get(
      `/api/admin/data`,options
    );
  }


export const getGetDataApiAdminDataGetQueryKey = () => [`/api/admin/data`] as const;
  

    
export const getGetDataApiAdminDataGetQueryOptions = <TData = Awaited<ReturnType<typeof getDataApiAdminDataGet>>, TError = AxiosError<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDataApiAdminDataGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof getDataApiAdminDataGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDataApiAdminDataGetQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDataApiAdminDataGet>>> = ({ signal }) => getDataApiAdminDataGet({ signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetDataApiAdminDataGetQueryResult = NonNullable<Awaited<ReturnType<typeof getDataApiAdminDataGet>>>
export type GetDataApiAdminDataGetQueryError = AxiosError<unknown>

/**
 * @summary Get Data
 */
export const useGetDataApiAdminDataGet = <TData = Awaited<ReturnType<typeof getDataApiAdminDataGet>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDataApiAdminDataGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDataApiAdminDataGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * @summary Create Template
 */
export const createTemplateApiAdminCreatePut = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ItemTemplate>> => {
    return axios.put(
      `/api/admin/create`,undefined,options
    );
  }



export const getCreateTemplateApiAdminCreatePutMutationOptions = <TError = AxiosError<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTemplateApiAdminCreatePut>>, TError,TVariables, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createTemplateApiAdminCreatePut>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTemplateApiAdminCreatePut>>, TVariables> = () => {
          

          return  createTemplateApiAdminCreatePut(axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateTemplateApiAdminCreatePutMutationResult = NonNullable<Awaited<ReturnType<typeof createTemplateApiAdminCreatePut>>>
    
    export type CreateTemplateApiAdminCreatePutMutationError = AxiosError<unknown>

    /**
 * @summary Create Template
 */
export const useCreateTemplateApiAdminCreatePut = <TError = AxiosError<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTemplateApiAdminCreatePut>>, TError,TVariables, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getCreateTemplateApiAdminCreatePutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Save Template
 */
export const saveTemplateApiAdminSavePatch = (
    itemTemplate: ItemTemplate,
    params: SaveTemplateApiAdminSavePatchParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.patch(
      `/api/admin/save`,
      itemTemplate,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getSaveTemplateApiAdminSavePatchMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTemplateApiAdminSavePatch>>, TError,{data: ItemTemplate;params: SaveTemplateApiAdminSavePatchParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof saveTemplateApiAdminSavePatch>>, TError,{data: ItemTemplate;params: SaveTemplateApiAdminSavePatchParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveTemplateApiAdminSavePatch>>, {data: ItemTemplate;params: SaveTemplateApiAdminSavePatchParams}> = (props) => {
          const {data,params} = props ?? {};

          return  saveTemplateApiAdminSavePatch(data,params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type SaveTemplateApiAdminSavePatchMutationResult = NonNullable<Awaited<ReturnType<typeof saveTemplateApiAdminSavePatch>>>
    export type SaveTemplateApiAdminSavePatchMutationBody = ItemTemplate
    export type SaveTemplateApiAdminSavePatchMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Save Template
 */
export const useSaveTemplateApiAdminSavePatch = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTemplateApiAdminSavePatch>>, TError,{data: ItemTemplate;params: SaveTemplateApiAdminSavePatchParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getSaveTemplateApiAdminSavePatchMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Delete Template
 */
export const deleteTemplateApiAdminIdDelete = (
    params: DeleteTemplateApiAdminIdDeleteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.delete(
      `/api/admin/id`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getDeleteTemplateApiAdminIdDeleteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTemplateApiAdminIdDelete>>, TError,{params: DeleteTemplateApiAdminIdDeleteParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTemplateApiAdminIdDelete>>, TError,{params: DeleteTemplateApiAdminIdDeleteParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTemplateApiAdminIdDelete>>, {params: DeleteTemplateApiAdminIdDeleteParams}> = (props) => {
          const {params} = props ?? {};

          return  deleteTemplateApiAdminIdDelete(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteTemplateApiAdminIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTemplateApiAdminIdDelete>>>
    
    export type DeleteTemplateApiAdminIdDeleteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Template
 */
export const useDeleteTemplateApiAdminIdDelete = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTemplateApiAdminIdDelete>>, TError,{params: DeleteTemplateApiAdminIdDeleteParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getDeleteTemplateApiAdminIdDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Login
 */
export const loginApiUserLoginPut = (
    params: LoginApiUserLoginPutParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.put(
      `/api/user/login`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getLoginApiUserLoginPutMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loginApiUserLoginPut>>, TError,{params: LoginApiUserLoginPutParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof loginApiUserLoginPut>>, TError,{params: LoginApiUserLoginPutParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof loginApiUserLoginPut>>, {params: LoginApiUserLoginPutParams}> = (props) => {
          const {params} = props ?? {};

          return  loginApiUserLoginPut(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type LoginApiUserLoginPutMutationResult = NonNullable<Awaited<ReturnType<typeof loginApiUserLoginPut>>>
    
    export type LoginApiUserLoginPutMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Login
 */
export const useLoginApiUserLoginPut = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loginApiUserLoginPut>>, TError,{params: LoginApiUserLoginPutParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getLoginApiUserLoginPutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Save
 */
export const saveApiUserSavePut = (
    params: SaveApiUserSavePutParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.put(
      `/api/user/save`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getSaveApiUserSavePutMutationOptions = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveApiUserSavePut>>, TError,{params: SaveApiUserSavePutParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof saveApiUserSavePut>>, TError,{params: SaveApiUserSavePutParams}, TContext> => {
 const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveApiUserSavePut>>, {params: SaveApiUserSavePutParams}> = (props) => {
          const {params} = props ?? {};

          return  saveApiUserSavePut(params,axiosOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type SaveApiUserSavePutMutationResult = NonNullable<Awaited<ReturnType<typeof saveApiUserSavePut>>>
    
    export type SaveApiUserSavePutMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Save
 */
export const useSaveApiUserSavePut = <TError = AxiosError<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveApiUserSavePut>>, TError,{params: SaveApiUserSavePutParams}, TContext>, axios?: AxiosRequestConfig}
) => {
    
      const mutationOptions = getSaveApiUserSavePutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * @summary Data
 */
export const dataApiUserDataGet = (
    params: DataApiUserDataGetParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UserData>> => {
    return axios.get(
      `/api/user/data`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getDataApiUserDataGetQueryKey = (params: DataApiUserDataGetParams,) => [`/api/user/data`, ...(params ? [params]: [])] as const;
  

    
export const getDataApiUserDataGetQueryOptions = <TData = Awaited<ReturnType<typeof dataApiUserDataGet>>, TError = AxiosError<HTTPValidationError>>(params: DataApiUserDataGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof dataApiUserDataGet>>, TError, TData>, axios?: AxiosRequestConfig}
): UseQueryOptions<Awaited<ReturnType<typeof dataApiUserDataGet>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDataApiUserDataGetQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof dataApiUserDataGet>>> = ({ signal }) => dataApiUserDataGet(params, { signal, ...axiosOptions });
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type DataApiUserDataGetQueryResult = NonNullable<Awaited<ReturnType<typeof dataApiUserDataGet>>>
export type DataApiUserDataGetQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Data
 */
export const useDataApiUserDataGet = <TData = Awaited<ReturnType<typeof dataApiUserDataGet>>, TError = AxiosError<HTTPValidationError>>(
 params: DataApiUserDataGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof dataApiUserDataGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDataApiUserDataGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

