import {
    Center,
    chakra,
    FormControl,
    FormErrorMessage, Heading,
    Input,
    InputGroup,
    InputLeftElement,
    Stack, VStack, Box, Button
} from "@chakra-ui/react";
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form'
import {MdOutlineNumbers} from "react-icons/md";
import {PiSignpostFill} from "react-icons/pi";
import {toast, ToastContentProps} from "react-toastify";
import {loginApiUserLoginPut} from "../../Client/default/default";
import {LoginApiUserLoginPutParams} from "../../Client/Models";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect} from "react";

const CMdOutlineNumbers = chakra(MdOutlineNumbers);
const CPiSignpostFill = chakra(PiSignpostFill);

const Login = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        order_id: Yup.string()
            .required('This field is required'),
        postal_code: Yup.string()
            .required('This field is required'),
    });

    const formOptions = {resolver: yupResolver(validationSchema)};
    const {
        handleSubmit,
        register,
        formState: {errors, isSubmitting},
    } = useForm<LoginApiUserLoginPutParams>(formOptions)

    const handleLoginSubmit = async (formData: LoginApiUserLoginPutParams) => {
        const toastId = toast.loading("Logging in...")

        await loginApiUserLoginPut(formData)
            .then((r) => {
                toast.update(toastId, { render: "Log in successful", type: "success", isLoading: false, autoClose: 2000 });

                navigate('/order', {
                    state: {
                        loginData: formData
                    }
                });
            }).catch(r => {
                toast.update(toastId, { render: "Order id or postal code incorrect", type: "error", isLoading: false, autoClose: 2000 });
                return Promise.resolve();
            });
    }

    useEffect(() => {
        if (searchParams.has('o') && searchParams.has('p')) {
            if (Number(searchParams.get('o'))) {
                handleLoginSubmit({
                    order_id: searchParams.get('o')!,
                    postal_code: searchParams.get('p')!
                })
            }
        }
    }, [handleLoginSubmit, searchParams])

    return (
        <Center w={'100%'}>
            <Box w={'80%'}>
                <form onSubmit={handleSubmit(handleLoginSubmit)}>
                    <VStack>
                        <Heading size='lg'>
                            Log in using your order details
                        </Heading>
                        <FormControl isInvalid={'order_id' in errors}>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<CMdOutlineNumbers color="gray.300"/>}
                                />
                                <Input
                                    id='order_number'
                                    placeholder={'Order number'}
                                    {...register('order_id')}
                                />
                            </InputGroup>
                            <FormErrorMessage>
                                {errors.order_id?.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={'postal_code' in errors}>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<CPiSignpostFill color="gray.300"/>}
                                />
                                <Input
                                    id='postal_code'
                                    placeholder={'Postal code'}
                                    {...register('postal_code')}
                                />
                            </InputGroup>
                            <FormErrorMessage>
                                {errors.postal_code?.message}
                            </FormErrorMessage>
                        </FormControl>
                        <Button
                            isLoading={isSubmitting}
                            loadingText={'Logging in...'}
                            type="submit"
                            variant="solid"
                            width="full"
                        >
                            {'Log in'}
                        </Button>
                    </VStack>
                </form>
            </Box>
        </Center>
    )
}

export {
    Login
}

